import {Component, OnDestroy, OnInit} from '@angular/core';
import {flyInOut} from "../_animations/fly-in-out.animation";
import {OrderService} from "../_services/order.service";
import {Order} from "../_models/order";
import {asyncScheduler, distinctUntilChanged, finalize, Subject, Subscription, switchMap, throttleTime} from "rxjs";
import {AlertService} from "../_services/alert.service";
import {ActivatedRoute} from "@angular/router";
import {TaximeterConnection} from "../_websocket/connections/taximeter-connection";
import {WebSocketService} from "../_services/web-socket.service";
import {Message} from "../_websocket/messages/message";
import {Taximeter} from "../_websocket/messages/taximeter";
import {EXECUTION_STATUSES} from "../_maps/execution-statuses";
import {OrderConnection} from "../_websocket/connections/order-connection";
import {GeoLocationService, Location} from "../_services/geo-location.service";
import {TransportService} from "../_services/transport.service";
import {Point} from "../_models/point";
import {DestinationPoint} from "../_models/destination-point";

class Status {
  constructor(public name: string, public identifier: string, public options?: any) {
  }
}

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  animations: [flyInOut]
})
export class OrderComponent implements OnInit, OnDestroy {
  order: Order|null = null;
  isLoading: boolean = false;
  isStatusUpdating: boolean = false;
  orderId: number|null = null;
  lastTaximeterMessage: Taximeter|null = null;
  nextStatuses: Status[] = [];
  currentDestinationNum: number = -1;
  executionStatusForAcceptation: string|null = null;
  executionStatusOptionsForAcceptation: any = {};
  isExecutionCompleted: boolean = false;
  maximizedPhotos: boolean[] = [];
  lastLocation: Location|null = null;
  routeStart: DestinationPoint|null = null;
  isLocationReceived = false;
  isLocationSent: boolean = false;
  isLocationSentError = false;

  private orderStream = new Subject<number>();
  private taximeterConnection: TaximeterConnection|null = null;
  private orderConnection: OrderConnection|null = null;
  private geolocationSubscription: Subscription|null = null;
  private receivedLocationTimer: any;
  private locationSentTimer: any;
  private pointsStream = new Subject<Location>();

  constructor(private orderService: OrderService,
              private alertService: AlertService,
              private route: ActivatedRoute,
              public geoLocationService: GeoLocationService,
              private transportService: TransportService,
              private webSocketService: WebSocketService) {
  }

  ngOnInit(): void {
    this.initOrderStream();
    this.initGeoLocation();
    this.route.params.subscribe(params => {
      this.orderId = parseInt(params['id']);
      this.loadOrder(this.orderId);
    })
  }

  private initGeoLocation(): void {
    this.initPointsStream();
    this.lastLocation = null;
  }

  private initPointsStream(): void {
    this.pointsStream = new Subject<Location>();
    this.pointsStream.pipe(
      distinctUntilChanged((a, b) => a.lat === b.lat && a.lon === b.lon),
      throttleTime(5000, asyncScheduler, {leading: false, trailing: true}),
      switchMap(location => {
        let point = new Point();
        point.lat = location.lat;
        point.lon = location.lon;
        point.t = location.timestamp;

        return this.transportService.sendPoint(point);
      })
    ).subscribe({
      next: () => {
        this.onLocationSent();
      },
      error: () => {
        this.isLocationSentError = true;
        this.initPointsStream();
      }
    })
  }

  private onLocationReceived(): void {
    this.isLocationReceived = true;
    this.isLocationSentError = false;

    if(this.receivedLocationTimer)
      clearTimeout(this.receivedLocationTimer);

    this.receivedLocationTimer = setTimeout(() => {
      this.isLocationReceived = false;
      clearTimeout(this.receivedLocationTimer);
      this.receivedLocationTimer = null;
    }, 5000);
  }

  private onLocationSent(): void {
    this.isLocationSent = true;

    if(this.locationSentTimer)
      clearTimeout(this.locationSentTimer);

    this.locationSentTimer = setTimeout(() => {
      this.isLocationSent = false;
      clearTimeout(this.locationSentTimer);
      this.locationSentTimer = null;
    }, 5000);
  }

  ngOnDestroy(): void {
    this.closeOrder();
  }

  private openOrder(): void {
    this.closeOrder();

    if(this.order.status == 'accepted') {
      this.taximeterConnection = this.webSocketService.createTaximeterConnection(this.orderId);
      this.taximeterConnection.message.subscribe(m => this.onTaximeterMessage(m));
      this.taximeterConnection.start();
    }

    this.orderConnection = this.webSocketService.createOrderConnection(this.orderId);
    this.orderConnection.message.subscribe(() => this.onOrderChangedMessage());
    this.orderConnection.start();

    this.geoLocationService.startLocationWatchingIf();
    this.geolocationSubscription = this.geoLocationService.subscribeOnLocationChange(location => {
      this.lastLocation = location;
      this.routeStart = new DestinationPoint();
      this.routeStart.lat = this.lastLocation.lat;
      this.routeStart.lon = this.lastLocation.lon;
      this.onLocationReceived();
      this.pointsStream.next(location);
    });
  }

  private closeOrder(): void {
    if(this.taximeterConnection) {
      this.taximeterConnection.close();
      this.taximeterConnection = null;
    }
    if(this.orderConnection) {
      this.orderConnection.close();
      this.orderConnection = null;
    }

    this.geolocationSubscription?.unsubscribe();
    this.geolocationSubscription = null;
    this.geoLocationService.stopLocationWatching();
  }

  private initOrderStream(): void {
    this.orderStream = new Subject<number>();
    this.orderStream.pipe(
      throttleTime(2500, asyncScheduler, {leading: true, trailing: true}),
      switchMap(id => {
        this.isLoading = true;
        this.closeOrder();
        return this.orderService.getEmployerOrder(id).pipe(finalize(() => this.isLoading = false));
      })
    ).subscribe({
      next: order => {
        this.order = order;
        this.calculateCurrentDestinationNum();
        this.initExecution();
        this.initLastTaximeterMessage();
        this.loadNextStatuses();
        this.openOrder();
      },
      error: r => {
        if(r.status === 404) {
          this.alertService.error('Заказ не найден!')
        } else {
          this.initOrderStream();
        }
      }
    });
  }

  private initExecution(): void {
    this.isExecutionCompleted = this.order.periods[0].execution_status === 'completed';
  }

  private calculateCurrentDestinationNum(): void {
    this.currentDestinationNum = -1;
    for(const historyRow of this.order.periods[0].history) {
      if(historyRow.execution_status === 'moved_to_order' || historyRow.execution_status === 'moving')
        this.currentDestinationNum ++;
    }
  }

  private loadNextStatuses(): void {
    if(this.order.status !== 'accepted') {
      this.nextStatuses = [];
      return;
    }

    this.orderService
      .getNextExecutionStatuses(this.order.periods[0])
      .subscribe({
        next: statuses => this.prepareNextStatuses(statuses),
        error: r => {}
      })
    ;
  }

  private prepareNextStatuses(statuses: string[]): void {
    this.nextStatuses = statuses.map(status => new Status(EXECUTION_STATUSES[status] || status, status));

    if(this.executionStatusForAcceptation && !this.nextStatuses.some(s => s.identifier === this.executionStatusForAcceptation))
      this.executionStatusForAcceptation = null;

    if(this.isExecutionCompleted) {
      if(this.order.payment_status === 'none') {
        this.nextStatuses.push(new Status('выставить счёт', 'bill'));
      } else if(this.order.pay_method === 'cash' && this.order.payment_status === 'wait_payment') {
        this.nextStatuses.push(new Status('наличные получены', 'bill_accept'));
      }
    } else {
      let payStatuses = this.nextStatuses.filter(s => s.identifier === 'request_card_payment' || s.identifier === 'cash_accepted');
      if(payStatuses.length > 0) {
        this.nextStatuses.push(new Status('изменить способ оплаты', 'change_pay_method', {
          method: payStatuses[0].identifier === 'request_card_payment' ? 'cash' : 'card'
        }));
      }
    }

    // console.log(this.nextStatuses);
  }

  private initLastTaximeterMessage(): void {
    if(this.order.status !== 'accepted' || this.isExecutionCompleted) {
      this.lastTaximeterMessage = null;
      return;
    }

    this.lastTaximeterMessage = new Taximeter(this.orderId,
      this.order.taximeter_duration,
      this.order.taximeter,
      this.order.cost - this.order.total_cost);
  }

  private loadOrder(id: number): void {
    this.orderStream.next(id);
  }

  private reloadOrder(): void {
    if(this.order)
      this.loadOrder(this.order.id);
  }

  private changeExecutionStatus(status: string): void {
    this.isStatusUpdating = true;
    this.orderService
      .updateExecutionStatus(this.order, this.order.periods[0], status)
      .pipe(finalize(() => this.isStatusUpdating = false))
      .subscribe({
        next: () => {
          this.executionStatusForAcceptation = null;
          if(status === 'completed')
            this.bill();
          else
            this.reloadOrder();
        },
        error: r => {
          if(r.status === 405) {
            this.alertService.warning('Статус не допустим');
          }
        }
      });
  }

  private bill(): void {
    this.isStatusUpdating = true;
    this.orderService
      .bill(this.order)
      .pipe(finalize(() => this.isStatusUpdating = false))
      .subscribe({
        next: () => {
          this.executionStatusForAcceptation = null;
          this.reloadOrder();
        },
        error: r => {
          if(r.status === 405) {
            this.alertService.warning('Статус не допустим');
          }
        }
      });
  }

  private acceptBill(): void {
    this.isStatusUpdating = true;
    this.orderService
      .billAccept(this.order)
      .pipe(finalize(() => this.isStatusUpdating = false))
      .subscribe({
        next: () => {
          this.executionStatusForAcceptation = null;
          this.reloadOrder();
        },
        error: r => {
          if(r.status === 405) {
            this.alertService.warning('Статус не допустим');
          }
        }
      });
  }

  private changePayMethod(method: string): void {
    this.isStatusUpdating = true;
    this.orderService
      .updateDestinationPayMethod(this.order, this.order.periods[0].destinations[this.currentDestinationNum], method)
      .pipe(finalize(() => this.isStatusUpdating = false))
      .subscribe({
        next: () => {
          this.executionStatusForAcceptation = null;
          this.reloadOrder();
        },
        error: r => {
          if(r.status === 405) {
            this.alertService.warning('Статус не допустим');
          }
        }
      });
  }

  private onTaximeterMessage(message: Message): void {
    this.lastTaximeterMessage = message as Taximeter;
  }

  private onOrderChangedMessage() {
    this.reloadOrder();
  }

  onChangeExecutionStatus(status: string, options: any): void {
    this.executionStatusForAcceptation = status;
    this.executionStatusOptionsForAcceptation = options;
  }

  onAcceptExecutionStatusChanging(): void {
    switch(this.executionStatusForAcceptation) {
      case 'bill':
        this.bill();
        break;
      case 'bill_accept':
        this.acceptBill();
        break;
      case 'change_pay_method':
        this.changePayMethod(this.executionStatusOptionsForAcceptation.method);
        break;
      default:
        this.changeExecutionStatus(this.executionStatusForAcceptation);
    }
  }
}
