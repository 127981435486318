import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LoginService} from "../_services/login.service";
import {AlertService} from "../_services/alert.service";
import {LoginResult} from "../login/login.component";
import {UserInfoService} from "../_services/user-info.service";

@Component({
  selector: 'app-login-with-order-link',
  templateUrl: './login-with-order-link.component.html',
  styleUrls: ['./login-with-order-link.component.scss']
})
export class LoginWithOrderLinkComponent implements OnInit {
  loginError: string|null = null;

  constructor(private route: ActivatedRoute,
              private loginService: LoginService,
              private userInfoService: UserInfoService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.login(params['linkValue']);
    });
  }

  private login(linkValue: string): void {
    this.loginService
      .loginWithOrderLink(linkValue)
      .then(result => {
        switch(result.result) {
          case LoginResult.AccessDenied:
            this.loginError = 'Доступ запрещён';
            break;
          case LoginResult.NotFound:
            this.loginError = 'Заказ не найден. Возможно, он был отменён или передан другому водителю.';
            break;
          case LoginResult.Ok:
            this.userInfoService
              .setup()
              .then(() => this.router.navigate(['/orders', result.orderId]))
            ;
            break;
        }
      })
  };
}
