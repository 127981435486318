import {Component, OnInit} from '@angular/core';
import {LoginService} from "../_services/login.service";
import {UserInfoService} from "../_services/user-info.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import {HttpErrorResponse} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";

export enum LoginResult {
  Ok,
  NotFound,
  AccessDenied,
  IncorrectPassword,
  SystemError
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: string = '';
  password: string = '';
  loginError: string|null = null;
  isLoginProcessing = false;
  isCodeRequesting = false;
  isCodeRequested = false;
  codeRequestError: string|null = null;

  private returnUrl: string = '/';

  constructor(private loginService: LoginService,
              private userInfoService: UserInfoService,
              private alertService: AlertService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  private login(): void {
    this.isLoginProcessing = true;
    this.loginError = null;
    this.loginService
      .login(this.username, this.password)
      .finally(() => this.isLoginProcessing = false)
      .then(result => {
        switch(result) {
          case LoginResult.AccessDenied:
            this.loginError = 'Доступ запрещён';
            break;
          case LoginResult.NotFound:
            this.loginError = 'Пользователь не найден';
            break;
          case LoginResult.IncorrectPassword:
            this.loginError = 'Неверный код';
            break;
          case LoginResult.Ok:
            this.userInfoService
              .setup()
              .then(() => this.router.navigate([this.returnUrl]))
            ;
            break;
        }
      })
      .catch(e => {
        this.loginError = `Ошибка: ${e.message}`;
        throw e;
      })
    ;
  }

  private requestCode(): void {
    if(this.username.trim() === '') {
      this.codeRequestError = 'Укажите номер телефона';
      return;
    }

    this.isCodeRequesting = true;
    this.codeRequestError = null;
    this.alertService.clear();
    this.loginService
      .restorePassword(this.username)
      .finally(() => this.isCodeRequesting = false)
      .then(() => this.isCodeRequested = true)
      .catch(e => {
        if(e instanceof HttpErrorResponse) {
          switch (e.status) {
            case 404:
              this.codeRequestError = 'Пользователь не найден';
              this.alertService.clear();
              break;
          }
        } else {
          this.codeRequestError = `Ошибка: ${e.message}`;
          throw e;
        }
      })
    ;
  }

  onLogin(form: NgForm): void {
    if(form.valid)
      this.login();
    else
      this.loginError = 'Укажите логин и код';
  }

  onRequestCode(): void {
    this.requestCode();
  }
}
