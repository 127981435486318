import {UserInfoService} from "./user-info.service";
import {Order} from "../_models/order";
import {Injectable} from "@angular/core";

@Injectable()
export class ApiUtilsService {
  constructor(private userInfoService: UserInfoService) {}

  getPrefixForList() {
    return '/employer/me'
  }

  getPrefixForOrder(order: Order): string {
    return '/employer/me'
  }

  getPrefixForEmployerOrder(): string {
    return '/employer/me'
  }

  getPrefixByFreighterId(freighterId: number, useSupportPrefix = false): string {
    return '/employer/me'
  }
}
