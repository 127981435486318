import {NgModule, isDevMode, APP_INITIALIZER, LOCALE_ID} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './index/index.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import {ScreenWakeLockService} from "./_services/screen-wake-lock.service";
import {GeoLocationService} from "./_services/geo-location.service";
import { LoginComponent } from './login/login.component';
import {NgOptimizedImage, registerLocaleData} from "@angular/common";
import localeRu from '@angular/common/locales/ru';
import {UserInfoService} from "./_services/user-info.service";
import * as moment from "moment";
import {RequestService} from "./_services/request.service";
import {SessionStorageService} from "./_services/session-storage.service";
import {TokenService} from "./_services/token.service";
import {HttpClientModule} from "@angular/common/http";
import {LoginService} from "./_services/login.service";
import {LocalStorageService} from "./_services/local-storage.service";
import {FormsModule} from "@angular/forms";
import { OrdersComponent } from './orders/orders.component';
import {AuthGuard} from "./_guards/auth.guard";
import {AlertService} from "./_services/alert.service";
import {AlertComponent} from "./alert/alert.component";
import {HttpErrorHandlerService} from "./_services/http-error-handler.service";
import {RequestWithErrorHandlerService} from "./_services/request-with-error-handler.service";
import {ApiUtilsService} from "./_services/api-utils.service";
import {OrderService} from "./_services/order.service";
import { OrderComponent } from './order/order.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {WebSocketService} from "./_services/web-socket.service";
import {FormatDurationPipe} from "./_pipes/format-duration.pipe";
import { LoginWithOrderLinkComponent } from './login-with-order-link/login-with-order-link.component';
import { DestinationInfoComponent } from './destination-info/destination-info.component';
import {FormatPhonePipe} from "./_pipes/format-phone.pipe";
import {TransportService} from "./_services/transport.service";
import {YandexMapLinkPipe} from "./_pipes/yandex-map-link.pipe";

registerLocaleData(localeRu)

export function initApp(userInfoService: UserInfoService) {
  return () => userInfoService
    .setup()
    .catch(e => {
      console.log(e);
      return e;
    });
}

@NgModule({
  declarations: [
    FormatDurationPipe,
    FormatPhonePipe,
    YandexMapLinkPipe,
    AppComponent,
    IndexComponent,
    LoginComponent,
    OrdersComponent,
    AlertComponent,
    OrderComponent,
    LoginWithOrderLinkComponent,
    DestinationInfoComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    FormsModule,
    NgOptimizedImage
  ],
  providers: [
    ScreenWakeLockService,
    GeoLocationService,
    UserInfoService,
    RequestService,
    SessionStorageService,
    TokenService,
    LoginService,
    LocalStorageService,
    AuthGuard,
    AlertService,
    HttpErrorHandlerService,
    RequestWithErrorHandlerService,
    ApiUtilsService,
    OrderService,
    WebSocketService,
    TransportService,
    {
      'provide': APP_INITIALIZER,
      'useFactory': initApp,
      'deps': [UserInfoService],
      'multi': true
    },
    {
      provide: LOCALE_ID,
      useValue: 'ru'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    moment.locale('ru_RU');
  }
}
