<div class="container vh-100 d-flex justify-content-center align-items-center">
  <div class="card shadow" style="width: 400px;">
    <div class="card-body">
      <h5 class="card-title text-center">Вход в систему</h5>
      <form #loginForm="ngForm" (ngSubmit)="onLogin(loginForm)">
        <!-- Поле ввода электронной почты -->
        <div class="mb-3">
          <label for="username" class="form-label">Логин</label>
          <input type="text" class="form-control" id="username" name="username" [(ngModel)]="username" required autofocus placeholder="Введите ваш логин">
        </div>
        <!-- Поле ввода пароля -->
        <div class="mb-3">
          <label for="password" class="form-label">Код</label>
          <input type="password" class="form-control" id="password" name="password" [(ngModel)]="password" required autofocus placeholder="Введите код">
        </div>
        <!-- Кнопка отправки формы -->
        <button type="submit" class="btn btn-primary w-100" [disabled]="isLoginProcessing">
          <span class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="isLoginProcessing"></span>
          <span *ngIf="isLoginProcessing">Вход...</span>
          <span *ngIf="!isLoginProcessing">Войти</span>
        </button>
        <div class="text-sm-center text-warning" *ngIf="loginError">{{ loginError }}</div>
      </form>
      <hr>
      <div class="text-center">
        <div class="spinner-grow spinner-grow-sm" role="status" *ngIf="isCodeRequesting">
          <span class="visually-hidden">Loading...</span>
        </div>
        <button class="btn btn-link" (click)="onRequestCode()" *ngIf="!isCodeRequesting">Получить код</button><br>
        <span *ngIf="isCodeRequested" class="text-success">Код отправлен на указанный номер телефона</span>
        <span *ngIf="codeRequestError" class="text-danger">{{ codeRequestError }}</span>
      </div>
    </div>
  </div>
</div>