import {Connection} from "../connection";
import {Subject} from "rxjs";
import {Message} from "../messages/message";
import {Taximeter} from "../messages/taximeter";

export class TaximeterConnection extends Connection {
  public message: Subject<Message> = new Subject();

  constructor(url: string, private orderId: number) {
    super(url);
  }

  protected override init() {
    console.log('Init taximeter connection...');

    this.sendMessage(JSON.stringify({
      "action": "listen_taximeter"
    }));
  }

  protected override onMessage(data: any) {
    let mData = data['data'];
    if (data['type'] == 'taximeter' && data['action'] == 'update' && mData.order == this.orderId) {
      this.message.next(new Taximeter(this.orderId, mData.duration, parseFloat(mData.cost), parseFloat(mData.discount)));
    }
  }
}