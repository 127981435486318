<div class="lock-container">
  <div>
    <button type="button" (click)="onSwitchLockScreen()">Lock screen</button>
  </div>
  <div *ngIf="locked">Экран заблокирован</div>
</div>
<div class="geo-container">
  <div>
    <button type=button (click)="onStartGeoLocationWatching()">Geo location</button>
  </div>
  <div *ngIf="location">
    <div *ngIf="isLocationActive">Геолокация активна</div>
    <div>[{{location.lat}},{{location.lon}}]</div>
  </div>
</div>