import {Injectable} from "@angular/core";
import {RequestService} from "./request.service";
import {UserInfo} from "../_models/user-info";
import {Account} from "../_models/account";
import {Subject, Observable, of} from "rxjs";
import { TokenService } from "./token.service";

@Injectable()
export class UserInfoService {
  private _userInfo?: UserInfo;
  private _token?: string;

  private loaded = false;
  private onLoadResult = new Subject<boolean>();
  private onUserLogin = new Subject<UserInfo>();


  constructor(private requestService: RequestService, private tokenService: TokenService) {
  }

  isPresent(): boolean {
    return this._userInfo !== undefined;
  }

  isPresentLazy(): Observable<boolean> {
    if (!this.loaded)
      console.log('User info not loaded');

    return this.loaded ? of(this.isPresent()) : this.onLoadResult.asObservable();
  }

  getUserLoginSubject(): Observable<UserInfo> {
    return this.onUserLogin.asObservable();
  }

  clear() {
    this.loaded = false;
    this._userInfo = undefined;
  }

  setup(): Promise<void> {
    console.log('Setup user info...');
    this.clear();
    this.loaded = false;
    return this.requestService.get('/token.json').toPromise()
      .then(r => r!.body.token)
      .then(token => {
        this._token = token as string;
        this.tokenService.token = token as string;
      })
      .then(() => this.requestService.get('/me.json').toPromise())
      .then(r => {
        let data = r!.body;
        let userInfo = new UserInfo();
        userInfo.role = data.role;
        userInfo.account = data.account as Account;

        console.log('User info loaded.');
        console.log(userInfo.role);
        return userInfo;
      })
      .then(user => {
        this._userInfo = user;
      })
      .then(() => {
        this.loaded = true;
        this.onLoadResult.next(true);
        console.log('user logged in ->',this.userInfo);
        this.onUserLogin.next(this.userInfo!);
      })
      .catch(e => {
        this.clear();
        this.loaded = true;
        this.onLoadResult.next(false);

        console.log('Setup of user info has not success.');

        throw e;
      });
  }

  isDriver(): boolean {
    return ['ROLE_DRIVER'].indexOf(this.userInfo?.role || '') >= 0;
  }

  get userInfo(): UserInfo|undefined {
    return this._userInfo;
  }

  get token(): string|undefined {
    return this._token;
  }

  getUserId(): number|undefined {
    return this._userInfo?.account!.id;
  }

  getUserName(): string|undefined {
    return this._userInfo?.account!.name;
  }
}
