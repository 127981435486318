import {Component, Input, OnInit} from '@angular/core';
import {Destination} from "../_models/destination";

@Component({
  selector: 'destination-info',
  templateUrl: './destination-info.component.html',
  styleUrls: ['./destination-info.component.scss']
})
export class DestinationInfoComponent implements OnInit {
  @Input() destination: Destination;

  simpleComponents: string|null = null;

  ngOnInit(): void {
    this.initSimpleComponents();
  }

  private initSimpleComponents() {
    let components: string[] = [];

    if(this.destination.floor)
      components.push(`${this.destination.floor} этаж`);

    if(this.destination.elevator)
      components.push('есть грузовой лифт');

    if(this.destination.delivery_to) {
      switch(this.destination.delivery_to) {
        case 'apartment':
          components.push('доставка до двери');
          break;
        case 'entrance':
          components.push('доставка до подъезда');
          break;
      }
    }

    if(this.destination.lifting) {
      if(this.destination.loading === this.destination.unloading) {
        components.push('требуются погрузочно-разгрузочные работы');
      } else if(this.destination.loading) {
        components.push('требуется погрузка');
      } else {
        components.push('требуется разгрузка');
      }
    }

    if(this.destination.delivery_product?.assembly)
      components.push('требуется сборка');

    this.simpleComponents = components.length > 0 ? components.join(', ') : null;
  }
}
