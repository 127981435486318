import {Order} from "./order";
import {EmployerOrderListFilter} from "./employer-order-list-filter";

export class EmployerOrderList {
  constructor(public page: number,
              public pageSize: number,
              public filter: EmployerOrderListFilter,
              public orders: Order[]) {
  }

  static empty(): EmployerOrderList {
    return new EmployerOrderList(0, 0, new EmployerOrderListFilter(), []);
  }

  concat(array) {
    this.orders = array.concat(this.orders);
    return this;
  }
}