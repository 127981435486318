import {Component, OnDestroy, OnInit} from '@angular/core';
import {ScreenWakeLockService} from "../_services/screen-wake-lock.service";
import {Subscription} from "rxjs";
import {GeoLocationService, Location} from "../_services/geo-location.service";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit, OnDestroy {
  private lockSubscription: Subscription|null = null;
  private locationSubscription: Subscription|null = null;

  public locked: boolean = false;
  public location: Location|null = null;
  public isLocationActive: boolean = false;

  constructor(private screenWakeLockService: ScreenWakeLockService, private geoLocationService: GeoLocationService) {
  }

  ngOnInit(): void {
    this.lockSubscription = this.screenWakeLockService.subscribeOnLock(locked => this.locked = locked)
  }

  ngOnDestroy(): void {
    this.lockSubscription?.unsubscribe();
    this.locationSubscription?.unsubscribe();
  }

  onSwitchLockScreen() {
    if(this.locked)
      this.screenWakeLockService.unlock();
    else
      this.screenWakeLockService.lock();
  }

  onStartGeoLocationWatching() {
    if(!this.isLocationActive) {
      this.locationSubscription = this.geoLocationService.subscribeOnLocationChange(location => this.location = location);
      this.isLocationActive = true;
    }
  }
}
