<div class="container mb-3 top-nav">
  <div class="row">
    <div class="col">
      <button class="back-button" [routerLink]="['/orders']">
        <i class="bi bi-chevron-left"></i> <!-- Иконка стрелки влево -->
      </button>
    </div>
  </div>
</div>
<div @flyInOut class="order-details-container container">
  <h1>
    <i class="bi bi-geo-alt-fill rounded"
       *ngIf="geoLocationService.isAvailable && !geoLocationService.isGeoError"
       [class.text-success]="isLocationReceived"
       [class.bg-success]="isLocationSent"
       [class.bg-danger]="isLocationSentError"
       [class.text-white]="isLocationSent || isLocationSentError"
    ></i>
    <i class="bi bi-geo-alt-fill text-danger" *ngIf="geoLocationService.isAvailable && geoLocationService.isGeoError"></i>
    <i class="bi bi-check-circle text-success" *ngIf="order?.status == 'completed'"></i>
    Заказ<span *ngIf="order"> #{{ order.draft.id }}</span>
  </h1>
  <div *ngIf="isLoading && !order" class="d-flex justify-content-center">
    <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div *ngIf="order">
    <div class="map" *ngIf="order.draft.static_map && order.draft.static_map['516']">
      <img [src]="order.draft.static_map['516']" alt="">
    </div>
    <div class="container" *ngIf="lastTaximeterMessage">
      <div class="taximeter text-center">
        <h1>Таксометр</h1>
        <div class="horizontal-layout">
          <div class="display-time" id="time">{{ (lastTaximeterMessage.duration / 60)|formatDuration }}</div>
          <div class="display-cost" id="cost">₽{{ lastTaximeterMessage.cost|currency:'RUB':'':'1.0-2' }}</div>
        </div>
      </div>
    </div>
    <div *ngIf="nextStatuses.length > 0" class="container next-statuses text-center">
      <div class="btn-group btn-group-lg" *ngIf="order.periods[0].execution_status != 'request_card_payment' || order.periods[0].destinations[currentDestinationNum].paid">
        <button *ngFor="let status of nextStatuses"
                type="button"
                class="btn btn-outline-primary"
                [class.active]="status.identifier === executionStatusForAcceptation"
                (click)="onChangeExecutionStatus(status.identifier, status.options)"
                data-bs-toggle="button"
                [attr.aria-pressed]="status.identifier === executionStatusForAcceptation"
        >{{ status.name }}</button>
      </div>
      <div class="wait-payment" *ngIf="order.periods[0].execution_status == 'request_card_payment' && !order.periods[0].destinations[currentDestinationNum].paid">
        ожидание оплаты
      </div>
      <ng-template [ngIf]="isStatusUpdating">
        <div class="mask"></div>
        <div class="shine"></div>
      </ng-template>
    </div>
    <div *ngIf="executionStatusForAcceptation" class="container accept-status text-center">
      <div class="btn-group btn-group-lg">
        <button type="button" class="btn btn-outline-success" (click)="onAcceptExecutionStatusChanging()"><i class="bi bi-check-lg"></i> подтвердить</button>
        <button type="button" class="btn btn-outline-danger" (click)="executionStatusForAcceptation = null"><i class="bi bi-x-lg"></i> отменить</button>
      </div>
      <ng-template [ngIf]="isStatusUpdating">
        <div class="mask"></div>
        <div class="shine"></div>
      </ng-template>
    </div>
    <ng-template ngFor let-period [ngForOf]="order.periods">
      <div class="order-address">
        <div class="address-row" *ngFor="let destination of period.destinations; let isLast = last; let i = index">
          <div class="marker">
            <div class="route-point" [class.current]="i <= currentDestinationNum"></div>
            <div class="route-line" *ngIf="!isLast"></div>
          </div>
          <div class="address">
            <a target="_blank" href="{{ destination.destination|yandexMapLink:routeStart }}" class="addr">{{ destination.destination.addr }}</a>
            <destination-info [destination]="destination" *ngIf="i >= currentDestinationNum - 1 || i <= currentDestinationNum + 1"></destination-info>
          </div>
        </div>
      </div>
    </ng-template>
    <div class="order-details">
      <div class="info detail-block">
        <div class="loaders">
          <div class="icon"></div>
          <div class="value">{{ order.draft.loaders }}</div>
        </div>
      </div>
      <div class="cost detail-block">
        <div class="icon"></div>
        <div class="value">{{ order.total_cost|currency:'RUB':'':'1.0-2' }}</div>
      </div>
    </div>
    <div *ngIf="order.draft.photos && order.draft.photos.length > 0" class="photos">
      <div class="photo" *ngFor="let photo of order.draft.photos; let i = index" (click)="maximizedPhotos[i] = !maximizedPhotos[i]" [class.full-width]="maximizedPhotos[i]">
        <img class="img-thumbnail" *ngIf="!maximizedPhotos[i]"
             [src]="photo.image_sizes.small.path"
             alt=""
             [width]="photo.image_sizes.small.width"
             [height]="photo.image_sizes.small.height">
        <img [src]="photo.image_sizes.mid.path" *ngIf="maximizedPhotos[i]" class="img-fluid" alt="">
      </div>
    </div>
    <div class="comment" *ngIf="order.draft.comment">{{ order.draft.comment }}</div>
  </div>
</div>
