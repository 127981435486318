import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserInfoService} from "../_services/user-info.service";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private userInfoService: UserInfoService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userInfoService.isPresentLazy()
      .pipe(
        map(present => {
          if(!present || !this.userInfoService.isDriver()) {
            console.log('User info was not present.');
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
          }
          return present;
        })
      );
  }

}
