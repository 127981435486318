import {Injectable} from "@angular/core";
import {AlertService} from "./alert.service";
import {Observable} from "rxjs";
import {HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {catchError} from "rxjs/operators";

@Injectable()
export class HttpErrorHandlerService {
  constructor(private _alertService: AlertService) {}

  addHandler(request: Observable<HttpResponse<any>>): Observable<HttpResponse<any>> {
    return request.pipe(catchError((error: any, caught: Observable<HttpResponse<any>>) => {
      console.log(error);

      if (error instanceof HttpErrorResponse) {
        switch (error.status) {
          case 500:
            this._alertService.error(this.buildMessage('Ошибка на сервере', error.error));
            break;
          case 400:
            this._alertService.warning(this.buildMessage('Некорректные данные', error.error));
            break;
          case 401:
            this._alertService.error(this.buildMessage('Требуется повторная авторизация', error.error));
            break;
          case 403:
            this._alertService.error(this.buildMessage('Нет доступа', error.error));
            break;
          case 404:
            this._alertService.error(this.buildMessage('Ресурс не найден', error.error));
            break;
          case 405:
            this._alertService.warning(this.buildMessage('Действие недопустимо', error.error));
            break;
          case 409:
            this._alertService.warning(this.buildMessage('Возникла конфликтная ситуация', error.error));
            break;
        }
      }

      throw error;
    }))
  }
  
  private buildMessage(title: string, error: any): string[] {
    let messages = [title];
    console.log(error);
    if(error['message']) {
      messages.push(error['message']);
    }
    return messages;
  }

  get alertService(): AlertService {
    return this._alertService;
  }

  clear() {
    this._alertService.clear();
  }
}
