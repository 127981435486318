import {Pipe, PipeTransform} from "@angular/core";
import {DestinationPoint} from "../_models/destination-point";

@Pipe({name: "yandexMapLink"})
export class YandexMapLinkPipe implements PipeTransform {
  transform(destination: DestinationPoint, prevDestination?: DestinationPoint): string {
    let link =  `https://yandex.ru/maps/?ll=${destination.lon},${destination.lat}&z=15&pt=${destination.lon},${destination.lat}&text=${destination.addr}`

    if (prevDestination) {
      link += `&rtext=${prevDestination.lat},${prevDestination.lon}~${destination.lat},${destination.lon}`
    }

    return encodeURI(link);
  }
}