import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {OrdersComponent} from "./orders/orders.component";
import {AuthGuard} from "./_guards/auth.guard";
import {OrderComponent} from "./order/order.component";
import {LoginWithOrderLinkComponent} from "./login-with-order-link/login-with-order-link.component";

const routes: Routes = [
  { path: '', redirectTo: '/orders', pathMatch: 'full' },
  {
    path: 'login',
    children: [
      { path: 'with/order/:linkValue', component: LoginWithOrderLinkComponent },
      { path: '', component: LoginComponent, pathMatch: 'full' }
    ]
  },
  {
    path: 'orders',
    canActivate: [AuthGuard],
    children: [
      { path: ':id', component: OrderComponent },
      { path: '', component: OrdersComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
