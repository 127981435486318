import {Injectable} from "@angular/core";
import {RequestService} from "./request.service";
import {HttpErrorResponse} from "@angular/common/http";
import {TokenService} from "./token.service";
import {LocalStorageService} from "./local-storage.service";

const PHONE_STORAGE = 'phone';

export enum LoginResult {
  Ok,
  NotFound,
  AccessDenied,
  IncorrectPassword,
  SystemError
}

export enum RestorePasswordResult {
  Ok,
  UnknownPhone,
  SystemError
}

class LoginWithOrderLinkResult {
  constructor(public result: LoginResult, public orderId?: number) {}
}

@Injectable()
export class LoginService {
  constructor(private requestService: RequestService, private tokenService: TokenService, private localStorageService: LocalStorageService) {}

  /**
   * Сохранение номера телефона
   * @param phone
   */
  savePhone(phone: string) {
    this.localStorageService.setItem(PHONE_STORAGE, phone);
  }

  /**
   * Восстановление номера телефона
   */
  restorePhone(): string|null {
    return this.localStorageService.getItem(PHONE_STORAGE);
  }

  login(username: string, password: string): Promise<LoginResult> {
    return this.requestService.get('/token.json')
      .toPromise()
      .then(r => r!.body.token)
      .then(token => this.tokenService.token = token)
      .then(() => {
        return this.requestService
          .post('/login/device.json', {
            username: username,
            password: password,
            type: 'driver'
          })
          .toPromise();
      })
      .then(() => LoginResult.Ok)
      .catch(e => {
        console.log(e);
        if(e instanceof HttpErrorResponse) {
          switch (e.status) {
            case 404:
              return LoginResult.NotFound;
            case 401:
              return LoginResult.AccessDenied;
            case 403:
              return LoginResult.IncorrectPassword;
            case 500:
              return LoginResult.SystemError;
          }
        }
        throw e;
      });
  }

  loginWithOrderLink(linkValue: string): Promise<LoginWithOrderLinkResult> {
    return this.requestService.get('/token.json')
      .toPromise()
      .then(r => r!.body.token)
      .then(token => this.tokenService.token = token)
      .then(() => {
        return this.requestService
          .post(`/login/device/with/driver/order/link/${linkValue}`, {})
          .toPromise();
      })
      .then(r => {
        return new LoginWithOrderLinkResult(LoginResult.Ok, r.body.orderId);
      })
      .catch(e => {
        if(e instanceof HttpErrorResponse) {
          switch(e.status) {
            case 404:
              return new LoginWithOrderLinkResult(LoginResult.NotFound);
            case 403:
              return new LoginWithOrderLinkResult(LoginResult.AccessDenied);
            case 500:
              return new LoginWithOrderLinkResult(LoginResult.SystemError);
          }
        }
        throw e;
      });
  }

  restorePassword(phone: string): Promise<RestorePasswordResult> {
    return this.requestService
      .post('/login/restore/by/phone.json', {
        phone: phone
      })
      .toPromise()
      .then(r => RestorePasswordResult.Ok)
      .catch(e => {
        console.log(e);
        if(e instanceof HttpErrorResponse) {
          switch (e.status) {
            case 404:
              return RestorePasswordResult.UnknownPhone;
            case 500:
              return RestorePasswordResult.SystemError;
          }
        }
        throw e;
      });
  }
}
