import {Injectable} from "@angular/core";
import {UserInfoService} from "./user-info.service";
import {environment} from "../../environments/environment";
import {OrderConnection} from "../_websocket/connections/order-connection";
import {OrdersConnection} from "../_websocket/connections/orders-connection";
import {TaxiConnection} from "../_websocket/connections/taxi-connection";
import {TaximeterConnection} from "../_websocket/connections/taximeter-connection";

@Injectable()
export class WebSocketService {
  constructor(
    private userInfoService: UserInfoService
  ) {}

  createOrderConnection(orderId: number): OrderConnection {
    return new OrderConnection(this.createUrl(), orderId);
  }
  createOrdersConnection(): OrdersConnection {
    return new OrdersConnection(this.createUrl());
  }

  createTaxiConnection() {
    return new TaxiConnection(this.createUrl());
  }

  createTaximeterConnection(orderId: number): TaximeterConnection {
    return new TaximeterConnection(this.createUrl(), orderId);
  }

  private createUrl(): string {
    return `${environment.webSocket}?token=${this.userInfoService.token}`;
  }
}
