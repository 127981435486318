import {Injectable} from "@angular/core";
import {Subject, Subscription} from "rxjs";
import {KalmanFilterArray} from "@bencevans/kalman-filter";
import {GeoUtils} from "../_utils/geo-utils";

// минимальное расстояние в метрах между новой и старой точкой, чтобы точка была отправлена получателю
const MIN_DISTANCE_TO_DISPATCH_LOCATION = 20;
// минимальная точность, при которой
const MIN_ACCURACY = 1000;

export class Location {
  constructor(public lat: number, public lon: number, public timestamp: number) {
  }
}

@Injectable()
export class GeoLocationService {
  private geoLocationStream = new Subject<Location>();
  private isWatchingReady = false;
  private _isAvailable = false;
  private _isGeoError = false;
  private watchId: number|null = null;
  private filter: KalmanFilterArray;
  private lastLocation: Location|null = null;

  subscribeOnLocationChange(observer: (location: Location) => void ): Subscription {
    this.startLocationWatchingIf();
    return this.geoLocationStream.subscribe(observer);
  }

  startLocationWatchingIf(): void {
    if (this.isWatchingReady) {
      return;
    }
    this.isWatchingReady = true;

    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by your browser');

      return;
    }

    this.watchId = navigator.geolocation.watchPosition(
      (position) => {
        // console.log(position);

        let accuracy = position.coords.accuracy;
        if(accuracy > MIN_ACCURACY)
          return;

        let lat = position.coords.latitude;
        let lon = position.coords.longitude;
        let timestamp = Math.round(position.timestamp / 1000)

        if(!this.lastLocation) {
          this.filter = new KalmanFilterArray({
            initialEstimate: [lat, lon],
            initialErrorInEstimate: 1
          });
        }

        const [estimate, ] = this.filter.update({
          measurement: [lat, lon],
          errorInMeasurement: 0.1
        });

        const [estimatedLat, estimatedLon] = estimate;

        // console.log(this.lastLocation, this.lastLocation && GeoUtils.calcDistance(this.lastLocation.lat, this.lastLocation.lon, estimatedLat, estimatedLon));

        if(this.lastLocation) {
          let distance = GeoUtils.calcDistance(this.lastLocation.lat, this.lastLocation.lon, estimatedLat, estimatedLon);
          if(distance < MIN_DISTANCE_TO_DISPATCH_LOCATION)
            return;
        }

        this.lastLocation = new Location(estimatedLat, estimatedLon, timestamp);
        this.geoLocationStream.next(new Location(estimatedLat, estimatedLon, timestamp));
        this._isAvailable = true;
        this._isGeoError = false;
      },
      () => {
        this._isGeoError = true;
        console.log('Geolocation error');
      }, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 30000
      }
    );
  }

  stopLocationWatching(): void {
    if (this.watchId) {
      navigator.geolocation.clearWatch(this.watchId);
      this.watchId = null;
      this.isWatchingReady = false;
    }
  }

  get isAvailable(): boolean {
    return this._isAvailable;
  }

  get isGeoError(): boolean {
    return this._isGeoError;
  }
}