import { trigger, transition, style, animate } from '@angular/animations';

export const flyInOut = trigger('flyInOut', [
  transition(':enter', [
    style({ transform: 'scale(0)', opacity: 0 }),
    animate('0.5s ease-in-out', style({ transform: 'scale(1)', opacity: 1 })),
  ]),
  transition(':leave', [
    animate('0.5s ease-in-out', style({ transform: 'scale(0)', opacity: 0 })),
  ])
]);