<div class="contacts" *ngIf="destination.contact_phone || destination.contact_name">
  <div class="contacts-header">Контактное лицо - </div>
  <div class="contact-name" *ngIf="destination.contact_name">{{ destination.contact_name }}</div>
  <div class="contact-phone" *ngIf="destination.contact_phone"><a
          href="tel:+{{destination.contact_phone}}">{{ destination.contact_phone|formatPhone }}</a></div>
</div>
<div class="simple-components" *ngIf="simpleComponents">{{ simpleComponents }}</div>
<div class="comment" *ngIf="destination.comment">{{ destination.comment }}</div>
<div *ngIf="destination.delivery_product" class="products">
  <table class="table table-sm"
         *ngIf="destination.delivery_product.lines && destination.delivery_product.lines.length > 0">
    <thead>
    <tr>
      <th>Наименование</th>
      <th>Кол-во</th>
      <th>Размеры, см</th>
      <th>Вес, кг</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let line of destination.delivery_product.lines">
      <td>{{ line.name }}</td>
      <td>{{ line.count }}</td>
      <td>{{ line.length }}x{{ line.width }}x{{ line.height }}</td>
      <td>{{ line.weight }}</td>
    </tr>
    </tbody>
  </table>
  <div class="payment" *ngIf="!destination.delivery_product.paid">
    <div class="cost">Стоимость груза: {{ destination.delivery_product.cost|currency:'RUB':'':'1.0-2' }}₽</div>
    <div class="payment-type" *ngIf="destination.delivery_product.pay_method == 'cash'">оплата наличными</div>
    <div class="payment-type" *ngIf="destination.delivery_product.pay_method == 'card'">оплата картой</div>
  </div>
</div>