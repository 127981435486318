import {Component, OnInit} from '@angular/core';
import {OrderService} from "../_services/order.service";
import {EmployerOrderList} from "../_models/employer-order-list";
import {finalize} from "rxjs";

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  orders: EmployerOrderList = EmployerOrderList.empty();
  isLoading: boolean = false;

  constructor(private ordersService: OrderService) {
  }

  ngOnInit() {
    this.loadOrders();
  }

  private loadOrders() {
    this.isLoading = true;
    this.ordersService
      .getEmployerOrders()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (orders) => this.orders = orders,
        error: () => {}
      })
    ;
  }
}
