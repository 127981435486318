<div class="container my-5">
  <h1>Заказы</h1>
  <div *ngIf="isLoading" class="d-flex justify-content-center">
    <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div class="container" *ngIf="orders.orders.length == 0 && !isLoading">
    <div class="alert alert-warning text-center" role="alert">
      <h4 class="alert-heading">У вас нет заказов!</h4>
      <p>Похоже, что у вас сейчас нет заказов.</p>
      <hr>
      <p class="mb-0">Если у вас есть вопросы, не стесняйтесь обращаться в нашу службу поддержки.</p>
    </div>
  </div>
  <ul class="order-list" *ngIf="orders.orders.length > 0 && !isLoading">
    <li class="order-item" *ngFor="let order of orders.orders" [routerLink]="['/orders', order.id]">
      <span class="order-number">#{{ order.draft.id }}</span>
      <div class="map" *ngIf="order.draft.static_map && order.draft.static_map['516']">
        <img [src]="order.draft.static_map['516']" alt="">
      </div>
      <ng-template ngFor let-period [ngForOf]="order.periods">
        <div class="order-address" *ngIf="period.destinations.length > 0">
          <div class="address-row">
            <div class="marker">
              <div class="route-point"></div>
              <div class="route-line"></div>
            </div>
            <div class="address">{{ period.destinations[0].destination.addr }}</div>
          </div>
          <div class="address-row" *ngIf="period.destinations.length > 1">
            <div class="marker">
              <div class="route-point"></div>
            </div>
            <div class="address">{{ period.destinations[period.destinations.length - 1].destination.addr }}</div>
          </div>
        </div>
      </ng-template>
      <div class="order-details">
        <div class="info detail-block">
          <div class="loaders">
            <div class="icon"></div>
            <div class="value">{{ order.draft.loaders }}</div>
          </div>
        </div>
        <div class="cost detail-block">
          <div class="icon"></div>
          <div class="value">{{ order.total_cost|currency:'RUB':'':'1.0-2' }}</div>
        </div>
      </div>
    </li>
  </ul>
</div>