import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Point} from "../_models/point";
import {Observable} from "rxjs";

@Injectable()
export class TransportService {
  constructor(
    private _requestService: RequestWithErrorHandlerService,
  ) {
  }

  sendPoint(point: Point): Observable<any> {
    return this._requestService.post('/transport/point.json', {
      lat: point.lat,
      lon: point.lon,
      time: point.t || Math.round(new Date().getTime() / 1000),
    });
  }
}