import {Connection} from "../connection";
import {Subject} from "rxjs";
import {Message} from "../messages/message";
import {NewOrder} from "../messages/new-order";


export class TaxiConnection extends Connection {
  public message: Subject<Message> = new Subject();


  constructor(url: string) {
    super(url);
  }

  protected override init() {
    console.log('Init taxi connection...');

    this.sendMessage(JSON.stringify({
      "action": "listen_taxi"
    }));
  }

  protected override onMessage(data: any) {
    let mData = data['data'];
    if (data['type'] == 'taxi') {
      switch (data['action']) {
        case 'new_order':
          this.message.next(new NewOrder(mData.order, mData.period, mData.draft))
          break;
      }
    }

  }
}
