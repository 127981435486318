import {Injectable} from "@angular/core";
import {Subject, Subscription} from "rxjs";

@Injectable()
export class ScreenWakeLockService {
  private locked = false;
  private wakeLock: any;

  private lockStream = new Subject<boolean>();

  constructor() {
  }

  subscribeOnLock(observer: (value: boolean) => void): Subscription {
    return this.lockStream.subscribe(observer);
  }

  async lock() {
    if(this.locked) {
      return;
    }

    this.locked = true;

    try {
      this.wakeLock = await (navigator as any).wakeLock.request('screen');
      this.lockStream.next(true);
      console.log('Locking screen');
    } catch(e) {
      console.log('Screen lock not supported');
    }
  }

  unlock() {
    this.locked = false;
    this.lockStream.next(false);
    console.log(this.wakeLock);

    if (this.wakeLock) {
      this.wakeLock.release();
      this.wakeLock = null;
      console.log('Released screen');
    }
  }
}