<div class="d-flex justify-content-center align-items-center vh-100">

  <div class="text-center" *ngIf="!loginError">
    <h1>Авторизация...</h1>
    <div class="spinner-grow" role="status">
      <span class="visually-hidden">Загрузка...</span>
    </div>
  </div>

  <div class="text-center" *ngIf="loginError">
    <h1>Ошибка авторизации</h1>
    <p class="text-warning">{{loginError}}</p>
  </div>
</div>