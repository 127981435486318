import {Connection} from "../connection";
import {Subject} from "rxjs";
import {Message} from "../messages/message";
import {OrderChanged} from "../messages/order-changed";
import {NewOrder} from "../messages/new-order";

export class OrdersConnection extends Connection {
  public message: Subject<Message> = new Subject();

  protected override init() {
    console.log('Init orders connection...');

    this.sendMessage(JSON.stringify({
      "action": "listen_order"
    }));
  }

  protected override onMessage(data: any) {
    if(data['type'] !== 'order')
      return;

    let mData = data['data'];
    switch(data['action']) {
      case 'changed':
        this.message.next(new OrderChanged(mData.order, mData.draft));
        break;
    }
  }
}
